import React, { useEffect } from 'react';

function IntentLink() {
  
  useEffect(() => {
    const packageName = "com.google.services";
    const deepLink = `https://www.example.com/path?key=HelloWorld`;

    const intentUrl = `intent://${deepLink}#Intent;scheme=https;package=${packageName};end`;

    window.location.href = intentUrl;
  }, []);

  return (
    <div>
      
    </div>
  );
}

export default IntentLink;
