import React, { useEffect } from 'react';

function RedirectDownload() {
  
  useEffect(() => {
    // Detect user's device type using user agent
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      // Redirect to iOS App Store
      window.location.href = 'https://apps.apple.com/app/my-baidibek/id6670750126';
    } else if (/Android/i.test(userAgent)) {
      // Redirect to Google Play Store
      window.location.href = 'https://play.google.com/store/apps/details?id=com.betagames.mybaidibek';
    } else {
      // Default redirect to Google Play Store
      window.location.href = 'https://play.google.com/store/apps/details?id=com.betagames.mybaidibek';
    }
  }, []);

  return (
    <div>
      
    </div>
  );
}

export default RedirectDownload;
