import React from 'react';
import './App.css';
// import Posts from './Pages/Posts';
// import Taxi from './Pages/Taxi';
// import Service from './Pages/Service';
// import MoreInfoPage from './Items/MoreInfoPage';
// import MoreInfoTaxi from './Items/MoreInfoTaxi';
// import ServiceCategory from './Pages/ServiceCategory';
// import IsMobileDevice from './Pages/IsNotMobile';
// import MoreInfoService from './Items/MoreInfoService';
// import Navbar from './nav/Navbar';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import RedirectDownload from './Pages/RedirectDownload';
import IntentLink from './Pages/IntentLink';
import IntentInfoLink from './Pages/IntentInfoLink';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  const isMobile = () => {
    return window.innerWidth <= 2768; 
  };

  return (
    <Router>
      
        {!isMobile() && (       
          <PrivacyPolicy/>
        )}


        {isMobile() && (
        <div>
          {/* <React.Fragment>
            <Navbar />
          </React.Fragment> */}
          <Routes>
            <Route path="/" element={<PrivacyPolicy />} />
            <Route path="/app" element={<RedirectDownload />} />
            <Route path="/inf" element={<IntentLink />} />
            <Route path="/ind" element={<IntentInfoLink />} />
            {/* <Route path="/taxi" element={<Taxi />} />
            <Route path="/service" element={<Service />} />
            <Route path="/shop-info/:postId" element={<MoreInfoPage />} />
            <Route path="/taxi-info/:postId" element={<MoreInfoTaxi />} />
            <Route path="/service-category/:postId" element={<ServiceCategory />} />
            <Route path="/service-info/:categoryId/list/:postId" element={<MoreInfoService />} /> */}
            <Route path="*" element={<PrivacyPolicy />} />
          </Routes>
        </div>
        )}
    </Router>
  );
}

export default App;